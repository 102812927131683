/// Flexible grid formatter \\\

/// treats sub-templates as cells in a single row for PC,
/// stacks as single column for mobile.
/// VARIATIONS of flex-grid__layout--
///   2x--50-50
///   2x--25-75
///   2x--75-25
///   2x--65-35
///   3x--50-25-25
///   3x--25-25-50
///   3x--25-50-25
///   3x--33
///   4x--25

@import '../../../../scss/theme-bootstrap';

.flex-grid {
  &__layout {
    height: auto;
    width: 100%;
    overflow: hidden;
  }
  &__item {
    width: 100%;
    height: 100%;
    min-height: 225px;
    @include breakpoint($portrait-up) {
      float: left;
      // 2x--50-50
      .flex-grid__layout--2x--50-50 & {
        float: left;
        width: 50%;
      }
      // 4x--25
      .flex-grid__layout--4x--25 & {
        width: 25%;
      }
      // 2x--25-75
      .flex-grid__layout--2x--25-75 & {
        &:nth-child(1) {
          width: 24%;
        }
        &:nth-child(2) {
          width: 74%;
          margin-left: 2%;
        }
      }
      // 2x--75-25
      .flex-grid__layout--2x--75-25 & {
        &:nth-child(1) {
          width: 74%;
        }
        &:nth-child(2) {
          width: 24%;
          margin-left: 2%;
        }
      }
      // 2x--65-35
      .flex-grid__layout--2x--65-35 & {
        &:nth-child(1) {
          width: 64%;
        }
        &:nth-child(2) {
          width: 34%;
          margin-left: 2%;
        }
      }
      // 3x--50-25-25
      .flex-grid__layout--3x--50-25-25 & {
        &:nth-child(1) {
          width: 50%;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 25%;
        }
      }
      // 3x--25-25-50
      .flex-grid__layout--3x--25-25-50 & {
        &:nth-child(1) {
          width: 25%;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 50%;
        }
      }
      // 3x--25-50-25
      .flex-grid__layout--3x--25-50-25 & {
        &:nth-child(1) {
          width: 25%;
        }
        &:nth-child(2) {
          width: 50%;
        }
        &:nth-child(3) {
          width: 25%;
        }
      }
      // 3x--33
      .flex-grid__layout--3x--33 & {
        float: left;
        width: 33.3%;
      }
    }
  }
}
