$color-error-message: #F00F00;

#account-my-appointments {
  .error-message {
    color: $color-error-message;
  }
}

#confirm {
  .confirm-container {
    .booking-step {
      .book-appt-container {
        .registration__sms_notifications-list {
          input {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

#booking-step3 {
  .book-appt-fieldset {
    .password-field {
      &.js-password-field {
        height: auto;
      }
    }
  }
}
