#index .checkout.checkout-single-page {
  .checkout-panel {
    &--order-summary {
      .select-box {
        &__options {
          font-size: 12px;
        }
      }
    }
    &__header,
    &__heading {
      .mobile-hidden {
        @include breakpoint($landscape-up) {
          display: inline-block !important;
        }
      }
    }
    .form-item.submit {
      margin: 10px 0;
    }
    &__content--carbon-offset {
      font-weight: bold;
    }
  }
}
