///
/// basic formatter
///
.content {
  .widget-margin-top {
    margin-top: 30px;
  }
  .field-content {
    .professional {
      &_banner {
        .content-block-large {
          &__inner {
            width: 55%;
          }
        }
      }
      &_salon {
        .content-block-large {
          &__text--1 {
            margin-bottom: 15%;
          }
          &__text--2 {
            margin-bottom: 1%;
          }
        }
      }
      &_behind_scenes {
        .content-block-large {
          &__text-wrapper {
            text-align: center;
          }
          &__text--2 {
            margin: 20px 0;
          }
        }
      }
    }
  }
}
