.cookie_legal {
  position: fixed;
  bottom: 0;
  background-color: $white;
  color: white;
  display: none;
  padding: 20px 25px 20px 20px;
  width: 100%;
  border: 10px solid $color-off-black;
  z-index: 10000;
  @include breakpoint($landscape-up) {
    width: 295px;
    height: auto;
    right: 10px;
    bottom: 0;
    display: inline-block;
  }
  a {
    color: $color-off-black;
    text-transform: none;
    text-decoration: underline;
  }
  &-language {
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }
  &-close_box {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #9fa617;
    font-size: 12px;
    cursor: pointer;
    i {
      &::before {
        background-color: $color-darkest-green;
      }
    }
  }
}
