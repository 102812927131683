.select-box {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  min-width: 150px;
  vertical-align: middle;
  &.select--wide {
    min-width: 300px;
  }
  &--replaced {
    display: none;
  }
  &--disabled {
    cursor: not-allowed;
  }
  &__label {
    display: inline-block;
    position: relative;
    padding: 0 30px 0 10px;
    height: 30px;
    line-height: 31px;
    border: $border-form;
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    background: $white;
    &::after {
      @include svg-icon-inline-mask('caret--down');
    }
    .select--wide & {
      min-width: 300px;
    }
    .select--plain & {
      border: none;
    }
    &.open {
      &::after {
        @include svg-icon-inline-mask('caret--up');
      }
    }

    // The icon content is also in the :after pseudoelement.
    &:after,
    &.open:after {
      content: '';
      color: $color-off-black;
      background-color: $color-off-black;
      position: absolute;
      height: 100%;
      top: 1px;
      right: 10px;
      width: 13px;
      line-height: 30px;
      text-align: right;
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 29px;
    min-width: 150px;
    min-height: 1em;
    max-height: 200px;
    margin: 0;
    padding: 0;
    background: $white;
    border: $border-form;
    cursor: pointer;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 99999;
    width: 100%;
    .select--wide & {
      min-width: 300px;
    }
    .select--plain & {
      border: none;
    }
    &.open {
      display: block;
    }
  }
  &__option {
    display: block;
    position: relative;
    padding: 0 30px 0 10px;
    height: 30px;
    line-height: 31px;
    white-space: nowrap;
    cursor: pointer;
    // @todo why do we have overflow here? it is setting a scroll on individual options.
    //    overflow: auto;

    &:hover {
      text-decoration: none;
      background: $color-light-gray;
    }
  }
} // .select-box
.regimen-fiscal {
  .select-box {
    &__label {
      display: block;
      height: 45px;
      line-height: 2.5;
      padding: 0 12px 0 14px;
      width: 270px;
      white-space: unset;
    }
    &__options {
      position: relative;
      top: 0;
    }
  }
}
