.site-header {
  &__nav-right {
    @if $cr20 == true {
      .site-header {
        &__menu-language-country {
          float: $ldirection;
          .gnav-country-language-chooser {
            margin-top: 5px;
          }
        }
        &__menu-item {
          .site-header__menu .site-header__menu-list--desktop & {
            float: $rdirection;
          }
        }
      }
    }
    .site-header__main & {
      @if $cr20 == true {
        display: block;
      }
    }
  }
  &__menu-desktop {
    margin-#{$rdirection}: 80px;
  }
}
.get_facts {
  .header-salon-takeover-menu {
    .menu--lvl-1 {
      float: $ldirection;
      margin-#{$ldirection}: 20px;
    }
  }
}
