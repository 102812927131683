.site-header {
  .user-loyalty {
    &__points-join {
      display: inline-block;
    }
  }
}

.site-footer {
  &__language {
    @include breakpoint($portrait-up) {
      display: none;
    }
  }
}

// Acomm - over-ride the _footer.scss and Enabling the footer for US
.site-footer {
  &__primary {
    .salon-takeover & {
      display: block;
    }
  }
}
