/*
  Colorbox Core Style:
  The following CSS is consistent between example themes and should not be altered.
*/
// @setup new site - this is cleaned up css from the plugin
// alter as needed per brand
// default is from example #3 dark bg, light overlay

.interstitial_popup_cboxLoaded {
  width: 740px;
  overflow: auto;
  height: 250px !important;
  border: 3px solid $color-off-black;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @include breakpoint($large-up) {
    height: 325px !important;
  }
  .interstial-popup {
    color: $color-off-black;
    .content-ca {
      margin: 0;
    }
  }
}

.interstitial_close_cbox_close {
  &:before {
    font-size: 18px !important;
    font-weight: bold !important;
    margin-left: 60px;
  }
}

.interstital-popup-cbox--ca {
  #cboxClose {
    @include breakpoint($portrait-up) {
      right: 35px;
    }
    @include breakpoint($landscape-up) {
      right: 19px;
    }
  }
}
