.viewcart {
  .checkout-panel {
    &--promo-bca,
    &--promo-earth_month,
    &--pure-privilege-reward-program,
    &--promo-salon_spa {
      @include breakpoint($landscape-up) {
        background: $color-light-linen;
        border-bottom: none;
        margin-bottom: 30px;
        margin-top: 35px;
        padding: 16px;
      }
      .promo-bca-content,
      .promo-earth_month-content,
      .pure-privilege-reward-program-content,
      .promo-salon_spa-content {
        padding: 0 0 15px 0;
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
        header {
          @include breakpoint($landscape-up) {
            margin: 0;
          }
          h2 {
            @include breakpoint($landscape-up) {
              margin: 0 0 0.2em 0;
            }
          }
        }
        .promo-bca-content__details,
        .promo-earth_month-content__details,
        .pure-privilege-reward-program-content__details,
        .promo-salon_spa-content__details {
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 20px;
            width: 80%;
          }
        }
        &__logo {
          @include breakpoint($landscape-up) {
            width: 115px;
            height: 107px;
          }
        }
        .promo-bca-content__img-wrap,
        .promo-earth_month-content__img-wrap,
        .pure-privilege-reward-program-content__img-wrap,
        .promo-salon_spa-content__img-wrap {
          @include breakpoint($landscape-up) {
            width: 19%;
          }
        }
        > div {
          float: #{$ldirection};
          @include breakpoint($landscape-up) {
            width: auto;
            float: #{$ldirection};
          }
        }
        &__button {
          @include button-add-to-bag;
        }
        .donation-amounts {
          @include breakpoint($landscape-up) {
            width: 60%;
          }
        }
      }
    }
    &--pure-privilege-reward-program {
      .checkout-panel {
        &__heading {
          margin: 0.5em 0 0.2em;
        }
      }
      .pure-privilege-reward-program-content {
        &__button {
          float: #{$rdirection};
          margin-#{$rdirection}: 50px;
          @include breakpoint($landscape-up) {
            float: none;
            margin-#{$rdirection}: 0;
            margin-#{$ldirection}: -15px;
          }
        }
        &__logo {
          width: 145px;
          height: 95px;
          @include breakpoint($landscape-up) {
            width: 118px;
            height: auto;
          }
        }
        &__terms {
          font-size: 13px;
          font-weight: bolder;
          margin: 0;
          padding-top: 10px;
          a {
            text-decoration: underline;
          }
        }
        &__title {
          display: flex;
          align-items: flex-start;
          margin: 0 1em 1em;
        }
        &__cards-container {
          display: flex;
          justify-content: space-between;
          margin: 0 2em;
          @include breakpoint($portrait-up) {
            margin: 0 1em;
          }
        }
        &__card {
          margin: 0 0.25em;
          flex: 1;
          min-height: 160px;
          border: 0.6px solid $light-gray;
          border-top: none;
        }
        &__card-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(to right, $black, $color-gray-darker);
          padding: 0.5em;
          min-height: 40px;
        }
        &__card-description {
          display: flex;
          text-align: center;
          flex-direction: column;
          min-height: 140px;
        }
        &__card-description-title {
          font-weight: 700;
        }
        &__card-description-text {
          color: $current-gray;
        }
        &__cta-container {
          margin: 1em;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        &__loyalty-cta {
          font-weight: 700;
          letter-spacing: 2px;
          margin: 1em;
        }
        &__success {
          background-color: $color-tmo-light-green;
          margin: 1em;
          padding: 1em;
          color: $color-green-1;
          width: 100%;
        }
        &__add-enrollment-cta {
          background: $white;
          border: 1.5px solid $color-off-black;
          color: $color-off-black;
          float: none;
          font-weight: 600;
          height: 38px;
          letter-spacing: 2px;
          line-height: 1.3;
          padding: 9px 20px;
        }
        .pure-privilege-reward-program-content__details {
          width: 55%;
          @include breakpoint($landscape-up) {
            width: 78%;
            padding-#{$ldirection}: 0;
          }
          .pure-privilege-reward-program-content__description {
            @include breakpoint($landscape-up) {
              display: inline-block;
            }
            & li {
              list-style-type: disc;
              margin-#{$ldirection}: 15px;
            }
          }
          .pure-privilege-reward-program-content__cart {
            @include breakpoint($landscape-up) {
              display: inline-block;
            }
          }
        }
        .pure-privilege-reward-program-content__img-wrap {
          width: 45%;
          @include breakpoint($landscape-up) {
            width: 22%;
          }
        }
      }
    }
  }
}
