.offerspick {
  margin: 0 auto;
  width: 100%;
  @include breakpoint($medium-up) {
    padding: 20px 0;
  }
  .offerspick__offers {
    display: flex;
    flex-wrap: wrap;
  }
  .offerspick__offer {
    text-align: center;
    padding: 12px 0 0;
    min-height: 260px;
    @include breakpoint($medium-up) {
      flex-direction: row;
      padding: 28px 1px 0;
      width: 25%;
    }
    &.is_selected {
      border-bottom: 0;
      .offerspick__button--remove {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
    &.is_soldout {
      @include opacity(0.4);
      .offerspick__button--soldout {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
  }
  .offerspick__offer__header {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.2em;
    margin-bottom: 18px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      letter-spacing: 0.1em;
    }
  }
  .offerspick__offer__subheader {
    font-size: 11px;
    letter-spacing: 0.2em;
    margin-bottom: 18px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      letter-spacing: 0.1em;
    }
  }
  .offerspick__header {
    padding: 40px 5px;
    text-align: center;
    @include breakpoint($medium-up) {
      padding: 40px;
    }
    &__title {
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &__choices {
      text-transform: uppercase;
    }
  }
  .offerspick__button--remove,
  .offerspick__header__remove {
    display: none;
  }
  .offerspick__button__container {
    text-align: center;
  }
  .offerspick__shades {
    @include clearfix;
    margin: 26px 0;
  }
  .offerspick__shade__image {
    padding-bottom: 20px;
  }
  .offerspick__shade__dot {
    background: $color-nobel;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
  }
  .offerspick__shade__name {
    font-size: 11px;
    line-height: 1.5;
    margin: 4px 0;
  }
  .offerspick__footer {
    @include swap_direction(margin, 50px 10px 20px 0);
    display: flex;
    justify-content: end;
  }
}
.checkout-panel__content {
  &.offerspick-action-buttons {
    display: flex;
    justify-content: end;
    margin: 0 10px;
  }
}
