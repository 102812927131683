#footer_chat_link {
  a.sticky-livechat__cta {
    font-weight: 300;
    border-bottom: none;
    line-height: 25px;
    &:hover,
    &:focus {
      text-decoration: underline !important;
    }
  }
}

.device-mobile {
  #footer_chat_link {
    .sticky-livechat__cta {
      border-bottom: none;
      font-weight: bold;
      margin-top: 9px;
      display: inline-block;
      &:hover,
      &:focus {
        text-decoration: none !important;
      }
    }
  }
}

:lang(fr) {
  .device-mobile {
    .site-footer {
      .sticky-livechat {
        display: none;
      }
    }
  }
  .sticky-livechat {
    display: none;
  }
}
.mpp-container {
  .tout {
    &__text {
      position: absolute;
    }
  }
}
