#index .checkout.checkout-single-page {
  .checkout-panel {
    &--review {
      .submit-order {
        margin-top: 10px;
      }
      .link {
        color: #392720;
        font-size: 14px;
        letter-spacing: 0.08em;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}

#confirm {
  .checkout-panel {
    border-top: 1px solid $light-gray;
    border-bottom: 0;
    .refer-friend {
      &__image-section {
        text-align: center;
        padding: 50px 0 25px;
        @include breakpoint($landscape-up) {
          float: $ldirection;
          padding: 30px 0 10px;
          width: 50%;
        }
      }
      &__content {
        text-align: center;
        padding-bottom: 30px;
        @include breakpoint($landscape-up) {
          float: $rdirection;
          padding: 89px 0 40px 30px;
          text-align: $ldirection;
          width: 50%;
        }
        a {
          background: $brown;
          color: $cream;
          padding: 11px 22px 7px;
        }
      }
      &__offer-content {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.2;
        @include breakpoint($landscape-up) {
          font-size: 22px;
          margin-bottom: 5px;
        }
      }
      &__reward-content {
        font-size: 14px;
        margin-bottom: 17px;
      }
    }
  }
}
