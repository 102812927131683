/* spp power reviews scss for fr */
html:lang(fr) {
  .spp_customer_reviews {
    #pr-reviewdisplay {
      .pr-helpful-yes {
        .pr-helpful-count:before {
          content: 'oui ·';
        }
      }
      .pr-helpful-no {
        .pr-helpful-count:before {
          content: 'non ·';
        }
      }
    }
    .spp_reviews {
      .spp_reviews_heading {
        @include breakpoint($small-down) {
          font-size: 18px;
        }
      }
    }
    .spp_reviews_button {
      a {
        &.button {
          @include breakpoint($small-down) {
            padding: 5px;
            font-size: 13px;
          }
        }
      }
    }
  }
}
