.elc-order-tracking {
  &__content {
    .elc-grid-column {
      a.elc-order-tracking-see-delivery-results {
        @include button;
        margin-top: 20px;
      }
    }
  }
}
