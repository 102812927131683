///
/// @file base/_state.scss
///
/// \brief Helper classes that allow us to control various states throughout css
///
/// These are placed last so that they can override any prior styling
///
///

.invisible {
  @include squish-text; // compass
}

.hidden,
.js_hidden {
  display: none !important;
}

.shown {
  display: block !important;
}

.is-opaque {
  @include opaque;
  visibility: visible;
}

.is-transparent {
  @include transparent;
  visibility: hidden;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flattened {
  height: 0;
  overflow: hidden;
}

.inflated {
  height: auto;
  overflow: visible;
}
// perlgem
.pc_hidden {
  display: block;
  @include breakpoint($medium-up) {
    display: none;
  }
}

.mobile_hidden {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.content_padding {
  margin-top: 20px;
  margin-bottom: 20px;
}
