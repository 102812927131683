///
/// @file layout/_page.scss
///
/// \brief
///
///

.container--relative {
  position: relative;
}

.site-content {
  margin: 0 auto;
  body:not(.enable_full_width) & {
    max-width: $max-width-large;
  }
  .bopis-faq {
    a {
      text-decoration: underline;
    }
  }
}

// important is needed here to ensure that all padding rules are overridden
// when a content block is shown in an iframe in Vulcan.
body.page-iframe {
  padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}
