/******* VIEWCART *********/

.viewcart {
  .checkout-panel {
    /* Shopping Cart */
    &--viewcart {
      .cart_item__error {
        &--hazmat {
          p {
            color: $red;
            margin-top: 20px;
            right: 0;
            @include breakpoint($landscape-up) {
              margin-top: 0;
              width: 50%;
            }
          }
        }
      }
    }
    .checkout-buttons-content {
      .button--disabled {
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        cursor: default;
        &.paypal-checkout {
          opacity: 0.4;
        }
      }
    }
    &--promo-bca,
    &--promo-earth_month {
      @include breakpoint($landscape-up) {
        .promo-bca-content,
        .promo-earth_month-content {
          header {
            margin: 0;
            h2 {
              margin: 0;
            }
          }
          .promo-bca-content__details,
          .promo-earth_month-content__details {
            width: 80%;
          }
          &__logo {
            width: 115px;
          }
          .donation-amounts {
            width: 70%;
          }
          &__label {
            margin: 0 8px 0 0;
          }
          .promo-bca-content__img-wrap,
          .promo-earth_month-content__img-wrap {
            width: 19%;
          }
        }
      }
    }
  }
  .offers-banner-formatter {
    padding-bottom: 0;
  }
} /* End Viewcrt */

#viewcart {
  .checkout-panel {
    .donation-price {
      border: 1px solid;
      display: inline-flex;
      margin-bottom: 10px;
      @include breakpoint($xlarge-up) {
        margin-bottom: 40px;
      }
      &-label {
        margin-top: 10px;
      }
      &__update-field,
      &__input-field {
        background: none;
        border: 0;
        color: $color-off-black;
        min-width: 40px;
        padding: 0;
      }
      &__input-field {
        border-#{$ldirection}: 1px solid $color-off-black;
        border-#{$rdirection}: 1px solid $color-off-black;
        max-width: 84px;
        text-align: center;
      }
      &__update-field {
        &:disabled {
          cursor: auto;
          opacity: 0.2;
        }
      }
    }
    &--multi-donation {
      .checkout-panel__heading {
        cursor: pointer;
        display: block;
        font-size: 21px;
        font-weight: bold;
        margin: 14px 0;
      }
      .promo-multi-donation {
        display: none;
        &__img-container {
          display: flex;
          height: 120px;
          width: auto;
          @include breakpoint($medium-up) {
            height: 145px;
          }
          .product-image {
            border: 1px solid $light-gray;
            border-radius: 10px;
            cursor: pointer;
            float: none;
            margin: 20px 20px 10px 0;
            max-width: 155px;
            @include breakpoint($medium-up) {
              max-width: 170px;
            }
            &.active {
              border: 3px solid $black;
            }
          }
        }
        &__container {
          width: 100%;
          .donation-amounts {
            margin-bottom: 25px;
            width: 100%;
            .donation-items,
            .donation-select {
              display: inline-block;
              margin: 10px -6px 0 0;
              width: 35%;
              @include breakpoint($medium-up) {
                margin-#{$rdirection}: 10px;
                width: 20%;
              }
              .donation-label {
                font-size: 16px;
              }
            }
          }
        }
        &__submit-btn {
          width: auto;
          &:disabled {
            opacity: 0.2;
          }
        }
        &__heading,
        &__product-name,
        &__header {
          font-weight: bold;
        }
        &__product-name {
          margin: 5px 0;
          text-transform: capitalize;
        }
        &__description {
          font-size: 16px;
          margin: 0;
        }
        &__header {
          margin-top: 15px;
        }
      }
    }
  }
  .checkout__carbon-offset {
    display: none;
  }
}
