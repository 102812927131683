@import '../../../../scss/theme-bootstrap';

.tabbed-block {
  padding-#{$ldirection}: 0;
  padding-#{$rdirection}: 0;
  &.default-padding {
    padding-top: 20px;
    padding-bottom: 0;
  }
  &__text {
    text-align: center;
  }
  &__tabs {
    display: table;
    font-size: 0;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    @include breakpoint($portrait-up) {
      margin: 20px auto;
      max-width: 80%;
    }
    a {
      @include button--inline;
      display: table-cell;
      width: 50%;
      font-size: 13px;
      vertical-align: middle;
      @include breakpoint($portrait-up) {
        display: inline-block;
        width: auto;
        font-size: 15px;
      }
    }
  }
}
