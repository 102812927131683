.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      text-decoration: underline;
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        color: $color-off-black !important;
        font-weight: normal;
        font-family: $primary-font;
        &:hover {
          color: $gray !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.optanon-alert-box-wrapper {
  div,
  p {
    display: inline;
    line-height: 0.05;
  }
  &.hide-accept-button {
    position: fixed;
    padding: 20px 25px 20px 20px;
    background-color: $white;
    color: $white;
    border: 10px solid $color-off-black;
    z-index: 10000;
    width: 100%;
    line-height: normal;
    @include breakpoint($landscape-up) {
      width: 295px;
      height: auto;
      right: 10px;
      bottom: 0;
      display: inline-block;
    }
    .optanon-alert-box-corner-close {
      position: static;
      .banner-close-button {
        position: absolute;
      }
    }
    .optanon-alert-box-button {
      float: none;
    }
    .optanon-alert-box-bg {
      .optanon-toggle-display {
        color: $color-off-black !important;
        text-decoration: underline;
        font-size: 12px;
        line-height: 16px;
        font-family: $primary-font;
        border-bottom: none;
        padding-bottom: 0;
        font-weight: normal;
        &:before {
          display: none;
        }
      }
      .banner-content {
        color: $black !important;
        font-size: 12px;
        line-height: normal;
        font-family: $primary-font;
      }
      .optanon-alert-box-body {
        margin: 0;
      }
      .optanon-alert-box-button-container {
        margin: 0;
        position: static;
      }
    }
  }
}

.cookie_legal {
  display: none !important;
}

.vendor-header-container {
  #optanon-popup-more-info-bar {
    .optanon-status-editable,
    .optanon-status-on {
      input[type='checkbox'] {
        & ~ label {
          white-space: nowrap;
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFloatingRoundedCorner {
      padding: 25px 24px 25px 22px;
      border: 10px solid $color-off-black;
      width: 100%;
      #{$rdirection}: 0;
      #{$ldirection}: auto;
      bottom: 0 !important;
      font-size: 13px;
      @include breakpoint($medium-up) {
        width: 295px;
        #{$rdirection}: 10px;
      }
      p {
        display: inline !important;
        font-size: 13px;
      }
      #onetrust-group-container {
        margin-#{$ldirection}: 0;
        float: none;
        #onetrust-policy {
          margin-top: 0;
          .banner-header {
            margin: 0;
          }
          #onetrust-policy-text {
            padding: 0;
            line-height: normal;
            color: $color-off-black;
            float: none;
          }
          #onetrust-close-btn-container {
            margin-#{$rdirection}: -5%;
            margin-top: -5%;
          }
        }
      }
      #onetrust-button-group-parent {
        padding: 0;
        float: none;
        #onetrust-pc-btn-handler {
          padding: 0;
          margin: 0;
          width: auto;
          color: $color-off-black !important;
          letter-spacing: normal;
          font-size: 13px;
          line-height: normal;
        }
      }
    }
    &.otFlat {
      #onetrust-group-container {
        width: 100%;
        #onetrust-policy {
          #onetrust-policy-text {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
      #onetrust-button-group-parent {
        width: 95%;
        text-align: #{$rdirection};
        #onetrust-button-group {
          button {
            background: transparent;
            border: none;
            text-decoration: underline;
            width: auto;
            opacity: 1;
          }
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          bottom: 20px;
          #{$ldirection}: 82%;
          top: auto;
          @include breakpoint($landscape-up) {
            top: auto;
            #{$ldirection}: 96%;
          }
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none !important;
      }
    }
    #onetrust-button-group {
      display: inline;
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    font-size: 14px !important;
    color: $color-darkest-green !important;
    min-width: auto;
    text-transform: capitalize;
    text-decoration: underline;
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: calc(100% - 190px);
        }
        #pc-title {
          font-size: 1.39em;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
