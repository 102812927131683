.tooltipster-aveda {
  background: $color-off-black;
  color: $white;
  max-width: 300px;
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $gray;
    }
  }
  .tooltipster-content {
    @include h4;
    overflow: hidden;
    padding: 15px;
  }
  .anon-message {
    display: block;
    text-transform: none;
    body.signed-in & {
      display: none;
    }
  }
}

.tooltipster-pure-privilege {
  background: $color-off-black;
  color: $white;
  max-width: 300px;
  .tooltipster-content {
    @include font-smoothing;
    font-size: 14px;
    font-weight: normal;
    line-height: get-line-height(14px, 18px);
    overflow: hidden;
    padding: 15px;
    text-transform: none;
  }
}
